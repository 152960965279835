import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./config/";
import Cookies from "js-cookie";
import "intro.js/introjs.css";
import amplitude from "amplitude-js";

import VModal from "vue-js-modal";
import Notifications from "vue-notification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueLazyload from "vue-lazyload";
import vWow from "v-wow";
import VueGtm from "vue-gtm";
import VueSwal from "vue-swal";
import { VueMasonryPlugin } from "vue-masonry";
import VTooltip from "v-tooltip";
import VueMeta from "vue-meta";
import VueIntro from "vue-introjs";
import VueObserveVisibility from "vue-observe-visibility";
import EditorLinkCard from "@c/molecules/editor-link-card.vue";
import MemoLinkCard from "@c/molecules/memo-link-card.vue";

Vue.use(VTooltip);
Vue.use(VueMasonryPlugin);
Vue.use(VueSwal);
Vue.use(VModal);
Vue.use(Notifications);
Vue.use(VueLazyload);
Vue.use(vWow);
Vue.use(VueMeta, {
    keyName: "metaInfo",
    attribute: "data-vue-meta",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: false
});
Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
    enabled: process.env.NODE_ENV === "development" ? false : true,
    debug: process.env.NODE_ENV === "development",
    vueRouter: router
});
Vue.use(VueIntro, {
    waitTimeout: 1500
});
Vue.use(VueObserveVisibility);

Vue.component("multiselect", Multiselect);

Vue.component("bullet-html-wrapper", {
    name: "BulletHtmlWrapper",
    props: {
        bulletData: {
            type: String,
            required: true
        }
    },
    render(h) {
        return h("div", [
            h(Vue.compile(`<span>${this.bulletData}</span>`))
        ])
    }
});

Vue.component("editor-link-card", EditorLinkCard);
Vue.component("memo-link-card", MemoLinkCard);

Vue.config.productionTip = false;

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(process.env.VUE_APP_AMPLITUDE_API_KEY);
Vue.prototype.$amplitude = amplitudeInstance;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");

window.Cookies = Cookies;
