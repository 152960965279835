<template>
    <div id="app" :class="{ 'overflow-hidden' : showSideBarTour | showDraftsList | showOnboarding | showFollowingList | showFollowersList }">
        <notifications
            :ignore-duplicates="true"
            :max="1"
            width="fit-content"
            group="app-notifications"
            classes="app-notifications"
        />
        <auth-modal @before-open="beforeOpen" @before-close="isAuthModalOpen = false" />
        <transition name="fade" mode="out-in">
            <fullscreen-loader v-if="isLoading" />
        </transition>

        <transition name="fade" mode="out-in">
            <router-view @is-loading="(value) => isLoading = value" />
        </transition>
    </div>
</template>

<script>
import { mapState } from "vuex";
import metaInfoMixins from "./mixins/metaInfoMixin";

export default {
    name: "App",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue"),
        AuthModal: () => import(/* webpackChunkName: "auth-modal" */ "@c/organisms/modals/auth/")
    },
    mixins: [metaInfoMixins],
    data() {
        return {
            isLoading: false,
            setInterval: null,
            isAuthModalOpen: false
        };
    },
    computed: {
        ...mapState({
            showSideBarTour: state => state.Application.showSideBarTour,
            showDraftsList: state => state.Application.showDraftsList,
            showOnboarding: state => state.Application.showOnboarding,
            showFollowingList: state => state.Application.showFollowingList,
            showFollowersList: state => state.Application.showFollowersList
        })
    },
    watch: {
        "$route": {
            handler() {
                if (this.isAuthModalOpen) {
                    this.$modal.hide("auth-modal");
                }
            }
        },
        "$route.meta.requiresAuth": {
            immediate: true,
            handler() {
                const requiresAuth = this.$route.meta.requiresAuth;
                if (requiresAuth) {
                    const isAuthenticated = this.$store.state.User.token || Cookies.get("token");
                    if (!isAuthenticated) {
                        this.setInterval = setInterval(() => {
                            this.$modal.show("auth-modal");
                        }, 200);
                    }
                }
            }
        }
    },
    methods: {
        beforeOpen() {
            this.isAuthModalOpen = true;
            clearInterval(this.setInterval);
        }
    }
}
</script>

<style lang="scss">
#app {

}
</style>
