<template>
    <a id="editor-link-card" :href="$attrs.url" target="_blank">
        <div v-if="$attrs.img" class="card-image">
            <img :src="$attrs.img">
        </div>
        <div class="card-container">
            <div v-if="$attrs.favicon || ($attrs.sitename && !Boolean($attrs.sitename))" class="favicon-and-name-container">
                <img
                    v-if="$attrs.favicon"
                    class="favicon"
                    :src="$attrs.favicon"
                    alt="Favicon"
                >
                <span v-if="$attrs.sitename && Boolean($attrs.sitename)" class="sitename">{{ $attrs.sitename }}</span>
            </div>
            <span class="card-description">{{ $attrs.description }}</span>
            <div class="web-link text-truncate">
                <div class="link-icon">
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.92254 7.61389L8.68009 7.37143C8.30456 6.9959 7.80324 6.78809 7.27094 6.78809C6.73863 6.78809 6.23914 6.9959 5.86179 7.37143L3.91669 9.31471C3.54116 9.69206 3.33334 10.1916 3.33334 10.7239C3.33334 11.2562 3.54116 11.7557 3.91669 12.133L4.40342 12.6197C4.77895 12.9953 5.28027 13.2031 5.81257 13.2031C6.34487 13.2031 6.84437 12.9953 7.22172 12.6197L7.9509 11.8906C8.05846 11.783 8.05846 11.6098 7.9509 11.5041C7.84335 11.3965 7.67017 11.3965 7.56444 11.5041L6.83525 12.2333C6.56181 12.5067 6.19904 12.6562 5.81257 12.6562C5.4261 12.6562 5.06333 12.5067 4.78989 12.2333L4.30316 11.7465C4.02971 11.4731 3.88023 11.1103 3.88023 10.7239C3.88023 10.3374 4.02971 9.97462 4.30316 9.70118L6.24826 7.75608C6.5217 7.48263 6.88447 7.33315 7.27094 7.33315C7.65741 7.33315 8.02018 7.48263 8.29362 7.75608L8.53607 7.99853C8.64363 8.10609 8.81681 8.10609 8.92254 7.99853C9.0301 7.8928 9.0301 7.71962 8.92254 7.61389Z"
                            fill="#EBEBF5"
                            fill-opacity="0.6"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.3"
                        />
                        <path
                            d="M12.0832 4.93971L11.5965 4.45298C11.221 4.07745 10.7197 3.86963 10.1874 3.86963C9.65506 3.86963 9.15557 4.07745 8.77821 4.45298L8.04903 5.18216C7.94147 5.28972 7.94147 5.4629 8.04903 5.56863C8.15658 5.67618 8.32976 5.67618 8.4355 5.56863L9.16468 4.83944C9.43812 4.566 9.80089 4.41652 10.1874 4.41652C10.5738 4.41652 10.9366 4.566 11.21 4.83944L11.6968 5.32617C11.9702 5.59962 12.1197 5.96239 12.1197 6.34886C12.1197 6.73532 11.9702 7.09809 11.6968 7.37154L9.75167 9.31481C9.47823 9.58826 9.11546 9.73774 8.72899 9.73774C8.34252 9.73774 7.97976 9.58826 7.70631 9.31481L7.46386 9.07236C7.3563 8.9648 7.18312 8.9648 7.07739 9.07236C6.97166 9.17991 6.96984 9.3531 7.07739 9.45883L7.31984 9.70128C7.69537 10.0768 8.19669 10.2846 8.72899 10.2846C9.2613 10.2846 9.76079 10.0768 10.1381 9.70128L12.0832 7.758C12.4588 7.38247 12.6666 6.88116 12.6666 6.34886C12.6666 5.81655 12.4588 5.31524 12.0832 4.93971Z"
                            fill="#EBEBF5"
                            fill-opacity="0.6"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.3"
                        />
                    </svg>
                </div>
                <span>
                    {{ $attrs.url }}
                </span>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "EditorLinkCard"
}
</script>

<style lang="scss" scoped>
#editor-link-card {
    text-decoration: none;
    display: flex;
    border-radius: 10px;
    height: 100px;
    overflow: hidden;
    background-color: #2C2C2E !important;

    .card-image {
        background-color: #242425;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;

        img {
            width: 100%;

            @media(max-width: 510px) {
                width: initial;
                height: 100%;
            }
        }
    }

    .card-container {
        color: white;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;

        .favicon-and-name-container {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .favicon {
                width: 20px;
                margin-right: 6px;
            }
        }

        .card-description {
            font-size: 18px !important;
            line-height: 18px !important;
            margin-bottom: 5px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .web-link {
            font-size: 14px !important;
            color: rgba(235, 235, 245, 0.6) !important;
            display: flex;
            align-items: center;
        }
    }
}
</style>
