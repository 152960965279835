/* eslint-disable no-prototype-builtins */
import Vue from "vue";
import store from "@/store/index";

const state = {
    currentBoard: {}
};

const mutations = {
    SET_CURRENT_BOARD(state, board) {
        state.currentBoard = board;
    }
};

const actions = {
    deleteBoard({ getters }, board) {
        return axios.delete(`/users/${getters.userId}/lists/${board.id}`).then(({ data }) => {
            Vue.notify({
                group: "app-notifications",
                type: "success",
                text: `Your board ${board.title} has been removed`,
                duration: 3000
            });

            return data;
        }).catch((error) => {
            console.log(error.response.data.errors.message);
        });
    },
    toggleMemoToBoard({}, payload) {
        return axios.post("/feeds/save", { list_id: payload.boardId, storm_id : payload.memoId }).then(({ data }) => {
            const notificationMessage = data.is_inside ? `Your Memo was saved in ${payload.boardTitle} Board` : "Memo has been removed from the board";
            Vue.notify({
                group: "app-notifications",
                type: "success",
                text: notificationMessage,
                duration: 3000
            });

            Vue.prototype.$amplitude.logEvent(data.is_inside ? "Save" : "Unsave", {
                entity_id: payload.memoId,
                boardId: data.list_id,
                event_name: data.is_inside ? "Save" : "Unsave",
                title: payload.boardTitle
            });

            return data;
        }).catch((error) => {
            Vue.notify({
                group: "app-notifications",
                type: "error",
                text: `Sorry, we're unable to add this Memo to the ${payload.boardTitle} Board right now. Please try again later.`,
                duration: 3000
            });
            console.log(error.response.data.errors.message);
        })
    },
    toggleMemoPin({}, payload) {
        return axios.post(`/boards/${payload.boardId}/pin/${payload.memoId}`).then(({ data }) => {
            const notificationMessage = data.is_pinned ? "The memo has been pinned" : "The memo has been unpinned"

            Vue.notify({
                group: "app-notifications",
                type: "success",
                text: notificationMessage,
                duration: 3000
            });

            return data;
        }).catch((error) => {
            Vue.notify({
                group: "app-notifications",
                type: "error",
                text: `Sorry, we're unable to pin this Memo. Please try again later.`,
                duration: 3000
            });
            console.log(error.response.data.errors.message);
        })
    },
    setCurrentBoard({ commit }, board) {
        commit("SET_CURRENT_BOARD", board);
    }
};

const getters = {
    userId() {
        return store.User.state.data.id;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
