import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import NotFound from "./views/404";
Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "landing",
        component: () => import(/* webpackChunkName: "landing" */ "./views/memos-landing/"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import(/* webpackChunkName: "about-us" */ "./views/about-us/"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/founders",
        name: "founders-landing",
        component: () => import(/* webpackChunkName: "founders-landing" */ "./views/founders-landing/"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/founders/faq",
        name: "founders-faq",
        component: () => import(/* webpackChunkName: "founders-faq" */ "./views/founders-landing/faq.vue"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/founders/program-terms-of-service",
        name: "founders-tos",
        component: () => import(/* webpackChunkName: "founders-tos" */ "./views/founders-landing/tos.vue"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "forgot-password"*/ "@v/auth/forgot-password")
    },
    {
        path: "/users/reset-password/:token",
        name: "password-reset",
        component: () => import(/* webpackChunkName: "password-reset"*/ "@v/auth/reset-password")
    },
    {
        path: "/legal",
        name: "memod-legal",
        component: () => import(/* webpackChunkName: "memod-legal"*/ "@v/legal/"),
        redirect: { name: "memod-tos" },
        children: [
            {
                path: "terms-of-service",
                name: "memod-tos",
                component: () => import(/* webpackChunkName: "memod-tos" */ "@v/legal/tos.vue"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "privacy-policy",
                name: "memod-privacy-policy",
                component: () => import(/* webpackChunkName: "memod-privacy-policy" */ "@v/legal/privacy-policy.vue"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "end-user-license-agreement",
                name: "memod-end-user-license-agreement",
                component: () => import(/* webpackChunkName: "memod-end-user-license-agreement" */ "@v/legal/end-user-license-agreement.vue"),
                meta: {
                    requiresAuth: false
                }
            }
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/404",
        name: "404",
        component: NotFound,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/web-app",
        name: "web-app",
        component: () => import(/* webpackChunkName: "web-app"*/ "@v/web-app/"),
        redirect: { name: "web-app-home" },
        children: [
            {
                path: "/browse-memos",
                name: "web-app-home-browse-memos",
                component: () => import(/* webpackChunkName: "web-app-home-browse-memos" */ "@v/web-app/browse-memos"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/home",
                name: "web-app-home",
                component: () => import(/* webpackChunkName: "web-app-home" */ "@v/web-app/home/"),
                redirect: { name: "web-app-home-following" },
                children: [
                    {
                        path: "notable",
                        name: "web-app-home-notable",
                        component: () => import(/* webpackChunkName: "web-app-home-notable" */ "@v/web-app/notable"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "following",
                        name: "web-app-home-following",
                        component: () => import(/* webpackChunkName: "web-app-home-following" */ "@v/web-app/home/following"),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ],
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/create",
                name: "web-app-create",
                component: () => import(/* webpackChunkName: "web-app-create" */ "@v/web-app/create/"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/edit/:slug(.+)-:id(\\d+)",
                name: "web-app-edit",
                component: () => import(/* webpackChunkName: "web-app-edit" */ "@v/web-app/create/"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/inbox",
                name: "web-app-inbox",
                component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                redirect: { name: "web-app-inbox-all-notifications" },
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: "all-notifications",
                        name: "web-app-inbox-all-notifications",
                        component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "likes-notifications",
                        name: "web-app-inbox-likes-notifications",
                        component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "comments-notifications",
                        name: "web-app-inbox-comments-notifications",
                        component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "followers-notifications",
                        name: "web-app-inbox-followers-notifications",
                        component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "mentions-notifications",
                        name: "web-app-inbox-mentions-notifications",
                        component: () => import(/* webpackChunkName: "web-app-inbox" */ "@v/web-app/inbox/"),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: "/:displayName/boards/:id",
                name: "web-app-board-view",
                component: () => import(/* webpackChunkName: "web-app-board-view" */ "@v/web-app/board-view/"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/topics/:topicSlug/",
                name: "web-app-topics",
                component: () => import(/* webpackChunkName: "web-app-topics" */ "@v/web-app/topics-view/"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/search/",
                name: "web-app-search",
                component: () => import(/* webpackChunkName: "web-app-home" */ "@v/web-app/search/"),
                redirect: { name: "web-app-search-memos" },
                children: [
                    {
                        path: ":searchString/memos",
                        name: "web-app-search-memos",
                        component: () => import(/* webpackChunkName: "web-app-search-memos" */ "@v/web-app/search/memos")
                    },
                    {
                        path: ":searchString/creators",
                        name: "web-app-search-creators",
                        component: () => import(/* webpackChunkName: "web-app-search-creators" */ "@v/web-app/search/people")
                    },
                    {
                        path: ":searchString/topics",
                        name: "web-app-search-topics",
                        component: () => import(/* webpackChunkName: "web-app-search-topics" */ "@v/web-app/search/topics")
                    }
                ],
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "/:displayName/settings",
                name: "web-app-profile-settings",
                component: () => import(/* webpackChunkName: "web-app-profile-settings" */ "@v/web-app/profile/settings"),
                meta: {
                    requiresAuth: true
                },
                redirect: { name: "web-app-profile-settings-edit" },
                children: [
                    {
                        path: "edit",
                        name: "web-app-profile-settings-edit",
                        component: () => import(/* webpackChunkName: "web-app-profile-settings-edit" */ "@v/web-app/profile/sidebar/edit"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "blocked-accounts",
                        name: "web-app-profile-settings-blocked-accounts",
                        component: () => import(/* webpackChunkName: "web-app-profile-settings-blocked-accounts" */ "@v/web-app/profile/sidebar/blocked-accounts"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "creator-program",
                        name: "web-app-profile-settings-creator-program",
                        component: () => import(/* webpackChunkName: "web-app-profile-settings-creator-program" */ "@v/web-app/profile/sidebar/creator-program"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "notifications",
                        name: "web-app-profile-settings-notifications",
                        component: () => import(/* webpackChunkName: "web-app-profile-settings-notifications" */ "@v/web-app/profile/sidebar/notifications"),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: "creator-program-dashboard",
                        name: "web-app-profile-settings-creator-program-dashboard",
                        component: () => import(/* webpackChunkName: "web-app-profile-settings-creator-program-dashboard" */ "@v/web-app/profile/sidebar/creator-dashboard"),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: "/settings/notifications",
                name: "web-app-profile-settings-notifications-insolate",
                component: () => import(/* webpackChunkName: "web-app-profile-settings-notifications" */ "@v/web-app/profile/sidebar/notifications")
            },
            {
                path: "/profile/:displayName",
                redirect: { name: "web-app-profile-memos" }
            },
            {
                path: "/:displayName",
                name: "web-app-profile",
                component: () => import(/* webpackChunkName: "web-app-profile" */ "@v/web-app/profile/"),
                redirect: { name: "web-app-profile-memos" },
                children: [
                    {
                        path: "memos",
                        name: "web-app-profile-memos",
                        component: () => import(/* webpackChunkName: "web-app-profile-memos" */ "@v/web-app/profile/memos"),
                        children: [
                            {
                                path: "published",
                                redirect: { name: "web-app-profile-memos" },
                                meta: {
                                    requiresAuth: false
                                }
                            },
                            {
                                path: "drafts",
                                redirect: { name: "web-app-profile-drafts" },
                                meta: {
                                    requiresAuth: false
                                }
                            }
                        ],
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: "drafts",
                        name: "web-app-profile-drafts",
                        component: () => import(/* webpackChunkName: "web-app-profile-drafts" */ "@v/web-app/profile/drafts"),
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: "boards",
                        name: "web-app-profile-boards",
                        component: () => import(/* webpackChunkName: "web-app-profile-boards" */ "@v/web-app/profile/boards"),
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: "saved",
                        name: "web-app-profile-saved",
                        redirect: { name: "web-app-profile-boards" },
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: "likes",
                        name: "web-app-profile-liked",
                        component: () => import(/* webpackChunkName: "web-app-profile-liked" */ "@v/web-app/profile/liked"),
                        meta: {
                            requiresAuth: false
                        }
                    },
                    {
                        path: "library",
                        name: "web-app-profile-library",
                        redirect: { name: "web-app-profile-liked" },
                        children: [
                            {
                                path: "liked",
                                redirect: { name: "web-app-profile-liked" },
                                meta: {
                                    requiresAuth: false
                                }
                            },
                            {
                                path: "saved",
                                redirect: { name: "web-app-profile-boards" },
                                meta: {
                                    requiresAuth: false
                                }
                            }
                        ],
                        meta: {
                            requiresAuth: false
                        }
                    }
                ],
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/:writer/:slug(.+)-:id(\\d+)/part-:partNumber",
                name: "memod-reader-serialized-part",
                component: () => import(/* webpackChunkName: "memod-reader" */ "./views/web-app/memod-reader/"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/:writer/:slug(.+)-:id(\\d+)",
                name: "memod-reader",
                component: () => import(/* webpackChunkName: "memod-reader" */ "./views/web-app/memod-reader/"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: "/notable/widget/:uuid",
                name: "web-app-notable-details",
                component: () => import(/* webpackChunkName: "web-app-home-following" */ "@v/web-app/notable/notable-details"),
                meta: {
                    requiresAuth: false
                }
            }
        ],
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "*",
        name: "404-not-found",
        component: NotFound,
        redirect: {
            name: "404"
        }
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

const readyUpApplication = function readyUpApplication() {
    return new Promise((resolve) => {
        if (store.getters["Application/isStateReady"]) {
            resolve();
        } else {
            store.dispatch("Application/getGlobalStateData")
                .then(() => resolve())
                .catch(() => resolve());
        }
    })
}

router.beforeEach(async(to, from, next) => {
    store.dispatch("Application/toggleSideBar", false);
    store.dispatch("Application/toggleDraftsList", false);
    store.dispatch("Application/toggleFollowingList", false);
    store.dispatch("Application/toggleFollowersList", false);

    if (from?.name == "web-app-inbox-all-notifications") {
        await axios.post("/notifications-read-all");
        next();
    }

    readyUpApplication().then(() => {
        Vue.prototype.$amplitude.logEvent("PageView", {
            name: to.name,
            path: `${window.location.hostname}${to.fullPath}`,
            previousPath: `${window.location.hostname}${from.fullPath}`
        });
        next();
    });
});

export default router;
