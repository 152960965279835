import headful from "headful";

export default {
    data() {
        return {
            memodMetaProperties: {
                type: "website",
                locale: "en_US",
                twitterAccount: "@memodapp",
                twitterCard: "summary_large_image",
                title: "Memo'd - Discover and share life-changing ideas",
                description: "Meet Memo'd - The knowledge-sharing app where you can discover and share life-changing ideas that can be read in less than 2 minutes",
                keywords: "memo, memo'd, memos, notes, note-sharing, notes-sharing, app, books, summaries, summarize, summary, bestsellers, lectures, lecture notes, learning, insights, book insights, bullet points, 10 points, blogs, podcasts, TLDR",
                siteName: "Memo'd",
                fbAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
                homeArtUrl: `${window.location.origin}/home-art.png`
            }
        }
    },
    watch: {
        "$route": {
            handler() {
                headful({
                    url: this.canonicalUrl
                });
            },
            immediate: true
        }
    },
    computed: {
        canonicalUrl() {
            return `${window.location.origin}${this.$route.path}`;
        }
    },
    metaInfo() {
        return {
            title: "Memo'd - Discover and share life-changing ideas",
            meta: [
                {
                    name: "description",
                    content: this.memodMetaProperties.description,
                },
                {
                    hid: "og:description",
                    property: "og:description",
                    content: this.memodMetaProperties.description,
                    vmid: "og:description"
                },
                {
                    hid: "og:title",
                    property: "og:title",
                    content: this.memodMetaProperties.title,
                    vmid: "og:title"
                },
                {
                    hid: "og:site_name",
                    property: "og:site_name",
                    content: this.memodMetaProperties.siteName,
                    vmid: "og:site_name"
                },
                {
                    hid: "twitter:description",
                    name: "twitter:description",
                    content: this.memodMetaProperties.description,
                    vmid: "twitter:description"
                },
                {
                    hid: "twitter:title",
                    name: "twitter:title",
                    content: this.memodMetaProperties.title,
                    vmid: "twitter:title"
                },
                {
                    hid: "keywords",
                    property: "keywords",
                    name: "keywords",
                    content: this.memodMetaProperties.keywords,
                    vmid: "keywords"
                },
                {
                    hid: "og:type",
                    property: "og:type",
                    content: this.memodMetaProperties.type,
                    vmid: "og:type"
                },
                {
                    hid: "og:url",
                    property: "og:url",
                    content: this.canonicalUrl,
                    vmid: "og:url"
                },
                {
                    hid: "og:locale",
                    property: "og:locale",
                    content: this.memodMetaProperties.locale,
                    vmid: "og:locale"
                },
                {
                    hid: "fb:app_id",
                    property: "fb:app_id",
                    content: this.memodMetaProperties.fbAppId,
                    vmid: "fb:app_id"
                },
                {
                    hid: "twitter:card",
                    name: "twitter:card",
                    content: this.memodMetaProperties.twitterCard,
                    vmid: "twitter:card"
                },
                {
                    hid: "twitter:site",
                    name: "twitter:site",
                    content: this.memodMetaProperties.twitterAccount,
                    vmid: "twitter:site"
                },
                {
                    hid: "og:image",
                    property: "og:image",
                    content: this.memodMetaProperties.homeArtUrl,
                    vmid: "og:image"
                },
                {
                    hid: "twitter:image",
                    name: "twitter:image",
                    content: this.memodMetaProperties.homeArtUrl,
                    vmid: "twitter:image"
                }
            ]
        };
    }
}
