import { format, differenceInMilliseconds } from "date-fns";

export const formatDate = (date, dateFormat = "yyyy-MM-dd") => {
    return format(new Date(date), dateFormat);
}

export const setTokens = (data) => {
    const { token, expires, refresh_token: rToken, refresh_token_expires: rExpires } = data;
    const currentDate = new Date();
    Cookies.set("access_token", token);
    Cookies.set("refresh_token", rToken, { expires: differenceInMilliseconds(new Date(rExpires), currentDate) });
    Cookies.set("token", token, { expires: differenceInMilliseconds(new Date(expires), currentDate) });
}
