import _isEmpty from "lodash/isEmpty";
import store from "@/store/index";

const state = {
    showLoader: false,
    showSideBar: false,
    showSideBarTour: false,
    showDraftsList: false,
    showFollowingList: false,
    showFollowersList: false,
    showOnboarding: false,
    isReloadFollowing: false,
    isMobileScreen: false
};

const mutations = {
    SHOW_LOADER(state, shouldShow) {
        state.showLoader = shouldShow;
    },
    SET_IS_MOBILE_SCREEN(state, isMobileScreen) {
        state.isMobileScreen = isMobileScreen;
    },
    TOGGLE_SIDE_BAR(state, value) {
        state.showSideBar = value;
    },
    TOGGLE_SIDE_BAR_TOUR(state, value) {
        state.showSideBarTour = value;
    },
    TOGGLE_DRAFTS_LIST(state, value) {
        state.showDraftsList = value;
    },
    TOGGLE_FOLLOWING_LIST(state, value) {
        state.showFollowingList = value;
    },
    TOGGLE_FOLLOWERS_LIST(state, value) {
        state.showFollowersList = value;
    },
    TOGGLE_ONBOARDING(state, value) {
        state.showOnboarding = value;
    },
    IS_RELOAD_FOLLOWING(state, value) {
        state.isReloadFollowing = value;
    }
};

const actions = {
    async getGlobalStateData({ dispatch }) {
        await dispatch("handleMobileScreen");
        await dispatch("User/getData", null, { root: true });
    },
    async handleMobileScreen({ commit }) {
        const mobileWidthSize = 768;
        commit("SET_IS_MOBILE_SCREEN", window.innerWidth <= mobileWidthSize);
    },
    resetGlobalData({ dispatch }) {
        dispatch("User/cleanUpData", {}, { root: true });
    },
    showLoader({ commit }, shouldShow) {
        commit("SHOW_LOADER", shouldShow);
    },
    toggleSideBar({ commit }, value) {
        commit("TOGGLE_SIDE_BAR", value);
    },
    toggleSideBarTour({ commit }, value) {
        commit("TOGGLE_SIDE_BAR_TOUR", value);
    },
    toggleDraftsList({ commit }, value) {
        commit("TOGGLE_DRAFTS_LIST", value);
    },
    toggleFollowingList({ commit }, value) {
        commit("TOGGLE_FOLLOWING_LIST", value);
    },
    toggleFollowersList({ commit }, value) {
        commit("TOGGLE_FOLLOWERS_LIST", value);
    },
    toggleOnboarding({ commit }, value) {
        commit("TOGGLE_ONBOARDING", value);
    },
    isReloadFollowing({ commit }, value) {
        commit("IS_RELOAD_FOLLOWING", value);
    }
};

const getters = {
    isStateReady() {
        return !_isEmpty(store.User.state.data);
    },
    isLoading(state) {
        return state.showLoader;
    },
    isReloadFollowing(state) {
        return state.isReloadFollowing
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
