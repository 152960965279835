import axios from "axios";

const axiosPublic = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL
});

axiosPublic.interceptors.response.use(response => {
    // Do something with response data
    return response;
}, error => {
    // Do something with response error
    return Promise.reject(error);
});


window.axiosPublic = axiosPublic;

export default axiosPublic;
