import { setTokens } from "@/utils";
import Cookies from "js-cookie";
import _isEmpty from "lodash/isEmpty";
import Vue from "vue";

const state = {
    data: {},
    newsletters: [],
    token: null
};

const mutations = {
    SET_DATA(state, data) {
        state.data = data;
    },
    SET_TOKEN(state, token) {
        state.token = token;
    },
    CLEAN_UP_DATA(state) {
        state.data = {};
        state.token = null;
    },
    CLEAN_UP_NOTIFICATION(state, notifications) {
        state.data = { ...state.data, new_notification: notifications };
    }
};

const actions = {
    async getData({ dispatch, state }) {
        if (state.token || Cookies.get("token")) {
            const { data } = await axios.get("/users/0");
            dispatch("setData", data);
            Vue.prototype.$amplitude.setUserId(data.id);
            !data.welcome && dispatch("Application/toggleOnboarding", true, { root: true });
        }
    },
    setData({ commit }, payload) {
        commit("SET_DATA", payload);
    },
    resetNotification({ commit }, notifications = 0) {
        commit("CLEAN_UP_NOTIFICATION", notifications);
    },
    setToken({ commit }, token) {
        commit("SET_TOKEN", token);
    },
    async emaillogIn({ dispatch }, { email, password }) {
        const { data } = await axiosPublic.post(`/auth`, { email, password });

        setTokens(data);

        await dispatch("setToken", data.token);

        return data;
    },
    async logout() {
        return axios.put("/auth/logout");
    },
    cleanUpData({ commit, dispatch }) {
        Cookies.remove("token");
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");

        dispatch("logout").then(() => {
            commit("CLEAN_UP_DATA");
        });
    }
};

const getters = {
    isLoggedIn(state) {
        return state.token != null;
    },
    loginType(state) {
        if (!_isEmpty(state.data.facebook)) {
            return "facebook";
        } else if (!_isEmpty(state.data.user)) {
            return "email";
        }
        return "";
    },
    userProfileImage(state) {
        const images = state.data.image;
        const hasProfileImage = !_isEmpty(images) && images["100"];

        if (!hasProfileImage) {
            return "";
        }

        return images["100"];
    },
    userIsReady(state) {
        return !_isEmpty(state.data);
    },
    userData: (state) => state.data
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
