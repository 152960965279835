/**
 * Dynamically import store elements.
 */
import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";
import modules from "./store/";

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== "production",
    plugins: [
        createMutationsSharer({
            predicate: (mutation) => {
                const predicate = [
                    "User/SET_DATA",
                    "User/SET_TOKEN",
                    "User/CLEAN_UP_DATA"
                ];
                return predicate.indexOf(mutation.type) >= 0;
            }
        })
    ]
});
