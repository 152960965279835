<template>
    <a
        id="memo-link-card"
        :href="`/${$attrs.displayname}/${$attrs.slug}-${$attrs.memoid}`"
    >
        <div class="card-container text-truncate">
            <span class="card-description">{{ $attrs.title }}</span>
            <div class="author text-truncate">
                <div class="user-icon">
                    <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.80005 10.1191C2.80005 9.07568 3.64233 8.2334 4.68576 8.2334H8.87624C9.91967 8.2334 10.762 9.07568 10.762 10.1191V11.5858C10.762 12.1641 10.2926 12.6334 9.71433 12.6334H3.84767C3.26938 12.6334 2.80005 12.1641 2.80005 11.5858V10.1191Z"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.7"
                            stroke-miterlimit="10"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.78138 2.36621C5.39433 2.36621 4.26709 3.49345 4.26709 4.8805C4.26709 6.26754 5.39433 7.39478 6.78138 7.39478C8.16842 7.39478 9.29566 6.26754 9.29566 4.8805C9.29566 3.49345 8.16842 2.36621 6.78138 2.36621Z"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.7"
                            stroke-miterlimit="10"
                        />
                    </svg>
                </div>
                <span>
                    {{ $attrs.displayname }}
                </span>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "MemoLinkCard"
}
</script>

<style lang="scss" scoped>
#memo-link-card {
    text-decoration: none;
    display: flex;
    border-radius: 10px;
    height: 80px;
    overflow: hidden;
    background-color: #2C2C2E !important;

    .card-container {
        color: white;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .card-description {
            font-size: 18px !important;
            line-height: 18px !important;
            margin-bottom: 5px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .author {
            font-size: 14px !important;
            color: rgba(235, 235, 245, 0.6) !important;
            display: flex;
            align-items: center;

            .user-icon {
                margin-right: 5px;
            }
        }
    }
}
</style>
