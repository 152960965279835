import axios from "axios";
import store from "../store";
import Cookies from "js-cookie";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { setTokens } from "@/utils";

const axiosV2 = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL_V2
});

axiosV2.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 499) {
            Cookies.remove("token");
            Cookies.remove("access_token");
            Cookies.remove("refresh_token");
            location.reload();
        }

        return Promise.reject(error);
    }
);

axiosV2.interceptors.request.use(
    config => {
        const token = store.state.User.token || Cookies.get("token");

        if (token) {
            store.dispatch("User/setToken", token);
            config.headers = {
                Authorization: token
            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const refreshAuthLogic = failedRequest => axiosV2.post("/refresh-token",
    {
        access_token: Cookies.get("access_token"),
        refresh_token: Cookies.get("refresh_token")
    },
    {
        headers: {
            "Authorization": Cookies.get("access_token")
        }
    }).then(tokenRefreshResponse => {
    store.dispatch("User/setToken", tokenRefreshResponse.data.token);
    setTokens(tokenRefreshResponse.data);
    failedRequest.response.config.headers["Authorization"] = tokenRefreshResponse.data.token;
    return Promise.resolve();
});

createAuthRefreshInterceptor(
    axiosV2,
    refreshAuthLogic,
    {
        statusCodes: [401]
    }
);

window.axiosV2 = axiosV2;

export default axiosV2;
