<template>
    <div class="not-found">
        <img src="@assets/img/logo-memod.svg" alt="404 Image">
        <div class="error-message">
            <span class="http-code"> 404 </span> - Sorry no results.
        </div>
        <button class="back-home-cta btn btn-primary" @click="$router.replace({ name: 'landing' })">
            Go to homepage
        </button>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style lang="scss" scoped>
.not-found {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
    background-image: url("~@assets/img/bg.jpg");
    background-repeat: no-repeat;
    overflow: auto;
    background-size: cover;

    img {
        margin-bottom: 8px;
        width: 260px;
    }

    .error-message {
        color: #19a98f;
        font-size: 1.3em;
        margin-bottom: 14px;

        .http-code {
            font-weight: 400;
            padding: 0;
        }
    }

    .back-home-cta {
        width: 212px;
        padding: 8px;
    }
}
</style>
