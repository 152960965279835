import Vue from "vue";
import * as rules from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import isUrl from "is-url";

// Vee validate default rules registrations
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

// Custom rules
extend("required", {
    ...rules.required,
    message: fieldName => `The ${fieldName} field is required.`
});

extend("email", {
    ...rules.email,
    message: "Please enter a valid email address."
});

extend("link", {
    validate(value) {
        return {
            required: true,
            valid: isUrl(value)
        };
    },
    computesRequired: true,
    message: "Must be a valid link, e.g. https://memod.com"
});

extend("max-length", {
    params: ["targetLength", "fieldName"],
    validate(value, { targetLength }) {
        return value.length <= targetLength;
    },
    message: "Only {targetLength} {fieldName} are allowed"
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
